<template>
    <div id="zizhi">
        <img src="@/assets/images/beian.jpg" alt="" height="80%" >
    </div>
</template>
<script>
export default {
    data(){
        return{

        }
    }
}
</script>
<style lang="less" scoped>
html,body{
    width: 100%;
    height: 100%;
    #zizhi{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

} 
</style>
